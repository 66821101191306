.scroll-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    border: none;
    background-color: #0077ff;
    color: #cacaca;
    font-size: 24px;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
    z-index: 1;
    border-radius: 5px;
  }
  
  .scroll-to-top-button:hover {
    background-color: #0052cc;
    color: #fffffe; /* Slight reduction in blue */
  }
  
  .scroll-to-top-button.active {
    opacity: 1;
    visibility: visible;
  }
  