/* about.css */
.about-container {
    position: relative;
    margin-bottom: 5%;
    overflow-x: hidden
}

.polygon-image {
    position: relative;
    width: 100%;
    height: 60vh;
    overflow: hidden;
}

.polygon-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    clip-path: polygon(0 0, 100% 0, 100% 80%, 70% 100%, 0 80%);
}

.polygon-image::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    clip-path: polygon(0 0, 100% 0, 100% 80%, 70% 100%, 0 80%);
}

.polygon-image::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 1);
    clip-path: polygon(0 0, 100% 0, 100% 80%, 70% 100%, 0 80%);
}

.cards-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: -10%;
    z-index: -1;
    box-sizing: border-box;
}

.custom-position-text-about {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 30%;
    left: 20%;
    transform: translate(-30%, -20%);
    z-index: 3;
}

.abc .ant-card-meta-title {
    border-top: 1px solid lightgray;
    padding-top: 2%;

    color: white !important;
    text-align: center;
}

.more-about-us {
    color: white;
    background: transparent;
    display: flex;
    align-items: center;
    margin-top: 3%;
    justify-content: center;
    font-size: 15px;
}

.more-about-us:hover {
    background-color: rgba(0, 0, 0, 0.2);
    border: none;
}

.abc .ant-card-meta-description {
    color: white !important;
    text-align: center;

}

.abc {

    padding-top: 10%;
}

@media (max-width: 768px) {
    .polygon-image img {
        clip-path: polygon(0 0, 100% 0, 100% 85%, 50% 100%, 0 85%);
    }

    .polygon-image::after {
        clip-path: polygon(0 0, 100% 0, 100% 85%, 50% 100%, 0 85%);
    }

    .polygon-image::before {
        background-color: rgba(0, 0, 0, 1);
        clip-path: polygon(0 0, 100% 0, 100% 85%, 50% 100%, 0 85%);
    }

    .custom-position-text-about {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        align-items: center;
    }

    .small-description-text {
        text-align: center;
        margin-bottom: 5%;
    }

    .polygon-image {
        height: 50vh;
    }

    .abc {
        padding-top: 20%;

    }

    .cards-container {

        margin-top: -20%;
    }
}

@keyframes transitionIn {
    from {
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    }

    to {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
}