.parent-request-component{
    position: absolute;
    top: 0;
    width: 100%;
}
.flex-row{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}