/* RightDiv.css */

.login-container {
  display: flex;
  flex: 1;
  padding: 10%;
  box-sizing: border-box;
  background-color: transparent;
  font-family: orbitron;
  font-size: large;
  color: white;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.login-form-container {
  background: transparent;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.7);
  padding: 5%;
  position: relative;
  overflow: hidden;
  z-index: 1
}

.login-title {
  color: white;
  text-align: center;
  margin-bottom: 10%;
}


.login-form-button {
  width: 100%;
  background: white;
  border: 1px solid white;
  color: #001529;
  z-index: 1
}

.login-form-button:hover {
  background: #001529;
  color: white;
  border: 1px solid white;
}

.border {}

.ant-input-affix-wrapper>input.ant-input {
  background-color: transparent !important;
  color: white;
}

.ant-input-affix-wrapper>input.ant-input::placeholder {
  color: white;
  /* Placeholder text color */
}

.border:hover {
  border: 2px solid white;
}

.border::selection {
  border: 2px solid white;
}

.top {
  position: absolute;
  border-radius: 100vmax;
  top: 0;
  left: 0;
  width: 0;
  height: 5px;
  background: linear-gradient(90deg,
      transparent 50%,
      rgba(255, 255, 255, 0.5),
      rgb(255, 255, 255));
  animation: animateTop 2s ease-in-out infinite;
}

@keyframes animateTop {
  25% {
    width: 100%;
    opacity: 1;
  }

  30%,
  100% {
    opacity: 0;
  }
}