.text {
  text-shadow: 0 0 7px rgb(255, 255, 255), 0 0 3px rgb(255, 255, 255);
  color: white;
}

.animation-2 {
  height: 30px;
  /* Decrease height */
  margin: 0;
  overflow: hidden;
  color: white;
  text-align: center;
  font-size: 15px;
  /* Decrease font size */
  text-shadow: 0 0 7px rgb(255, 255, 255), 0 0 3px rgb(255, 255, 255);
}

.animation-2>div>div {
  padding: 0.25rem 0.75rem;
  height: 1.81rem;
  /* Decrease div height */
  margin-bottom: 1.81rem;
  /* Decrease margin bottom */
  display: inline-block;
}

.animation-2 div:first-child {
  animation: text-animatione 9s infinite;
  /* Adjusted duration */
}

.first-text div {
  background-color: rgba(255, 80, 80, 0.3);
}

.second-text div {
  background-color: rgba(59, 157, 255, 0.37);
}

.third-text div {
  background-color: rgba(154, 65, 255, 0.349);
  overflow: ellipsis;
}

/* Removed fourth-text class */

@keyframes text-animatione {
  0% {
    margin-top: 0;
  }

  10% {
    margin-top: 0;
  }

  33% {
    margin-top: -3rem;
    /* Position for first transition */
  }

  66% {
    margin-top: -6rem;
    /* Position for second transition */
  }

  100% {
    margin-top: 0;
    /* Reset to original */
  }
}