.neon-cursor-container {
    position: relative;
    z-index: 999;
  }
  
  .neon-effect {
    position: relative;
    
    z-index: 9999;
    /* Add any custom styles for the neon effect */
    /* For example, you can use CSS properties like text-shadow, box-shadow, or background-color to create the neon effect */
  }