.text{
    text-shadow: 0 0 7px rgb(255, 255, 255), 0 0 3px rgb(255, 255, 255);
    color: white;
}
.animation {
    height:50px;
    margin: 0;
    overflow:hidden;
    color: white;
    text-align: center;
    font-size: 1.9rem;
    text-shadow: 0 0 7px rgb(255, 255, 255), 0 0 3px rgb(255, 255, 255);
  }
.animation > div > div {
    padding: 0.25rem 0.75rem;
    height:2.81rem;
    margin-bottom: 2.81rem;
    display:inline-block;
  }
  
  .animation div:first-child {
    animation: text-animation 15s infinite;
  }
  
  .first div {
    background-color:dodgerblue;
  }
  .second div {
    background-color:dodgerblue;
  }
  .third div {
    background-color:dodgerblue;
  }
  .fourth div {
    background-color:dodgerblue;
  }
  
  @keyframes text-animation {
    0% {margin-top: 0;}
    10% {margin-top: 0;}
    20% {margin-top: -5.62rem;}
    30% {margin-top: -11.24rem;}
    40% {margin-top: -18.24rem;}
    50% {margin-top: -18.24rem;}
    60% {margin-top: -11.24rem;}
    70% {margin-top: -5.62rem;}
    90% {margin-top: 0;}
    100% {margin-top: 0;}
  }
  