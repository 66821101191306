.event-image {
  width: 100%;
  height: 50%;
  position: relative;
  object-fit: cover;
  clip-path: polygon(0 0, 100% 0, 100% 80%, 50% 100%, 0 80%);
  transition: transform 0.3s;
  /* Add a transition effect to smooth the scale change */
}

.event-image:hover {
  transform: scale(1.05);
  /* Apply scale transformation on hover */
}

.hover-3 {
  position: relative;
  color: dodgerblue;
  width: 100%;
}

.hover-3::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  /* Adjust the height as needed */
  background-color: dodgerblue;
  /* Underline color */
}

.hover-2 {
  --c: #1095c1;
  /* the color */
  --b: .1em;
  /* border length*/
  --d: 20px;
  /* the cube depth */

  --_s: calc(var(--d) + var(--b));

  color: var(--c);
  border: solid #0000;
  border-width: var(--b) var(--b) var(--_s) var(--_s);
  background:
    conic-gradient(at left var(--d) bottom var(--d),
      #0000 90deg, rgb(255 255 255 /0.3) 0 225deg, rgb(255 255 255 /0.6) 0) border-box,
    conic-gradient(at left var(--_s) bottom var(--_s),
      #0000 90deg, var(--c) 0) 0 100%/calc(100% - var(--b)) calc(100% - var(--b)) border-box;
  transform: translate(calc(var(--d)/-1), var(--d));
  clip-path:
    polygon(var(--d) 0%,
      var(--d) 0%,
      100% 0%,
      100% calc(100% - var(--d)),
      100% calc(100% - var(--d)),
      var(--d) calc(100% - var(--d)));
  transition: 0.5s;
}

.hover-2:hover {
  transform: translate(0, 0);
  clip-path:
    polygon(0% var(--d),
      var(--d) 0%,
      100% 0%,
      100% calc(100% - var(--d)),
      calc(100% - var(--d)) 100%,
      0% 100%);
}

@media (max-width: 767px) {

  /* Your mobile-specific styles go here */
  .hover-2 {
    --d: 10px;
  }

}


.event-description-truncate {
  white-space: normal;
  /* allow text to wrap */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* limit the number of lines to 3 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* hide any overflowing text */
}

.truncate-title {
  white-space: nowrap;
  /* Ensure text stays on a single line */
  overflow: hidden;
  /* Hide any overflowing text */
  text-overflow: ellipsis;
  /* Add ellipsis for overflowed text */
}