.welcomeimg {
  width: 200px;
  height: 200px;
}


.ant-card-head-title {
  color: dodgerblue;
}

.heading-h1 {
  color: blue;
  font-weight: 500;
}

.Dashboard-four-buttons {
  background: "red";
  text-align: "center";
}

.buttons-text {
  color: white;
}

.buttonContainer {
  padding: 5%;
  border-radius: 5%;
}

@media screen and (max-width: 767px) {
  .ant-card .ant-card-head {
    padding: 0 0px;
  }
}