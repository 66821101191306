/* Styles for the vertical scrollbar */
::-webkit-scrollbar {
  width: 10px;
  /* Width of the scrollbar */
}

/* Track styles (background of the scrollbar) */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Color of the track */
}

/* Handle styles (the draggable part of the scrollbar) */
::-webkit-scrollbar-thumb {
  background-color: #b9b9b9;
  /* Color of the handle */
  border-radius: 5px;
  /* Rounded corners */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #888;
  /* Darker color on hover */
}




/* Show scrollbar always */
::-webkit-scrollbar {
  display: auto;
}

.container {
  display: flex;
  overflow-x: auto;
  width: 100%;
  padding: 20px 0;
  padding-bottom: 0;

}

.container::-webkit-scrollbar {
  display: none
    /* Width of the scrollbar */
}

.card {
  flex-shrink: 0;
  width: 300px;
  height: 400px;
  margin-left: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  /* Add this line to make sure pseudo-element is positioned relative to .card */
  transition: background-color 0.3s;
  /* Add a smooth transition for background-color change */
  margin-top: 20px;
}

.card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 119, 255, 0.5), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));

  opacity: 0;
  pointer-events: none;
  /* Ensure pseudo-element doesn't interfere with hover state */
  transition: opacity 0.3s;
  /* Add a smooth transition for opacity change */
}

.card:hover {
  background-color: rgba(0, 119, 255, 0.5);
  /* Adjust the opacity as needed */
}

.card:hover::before {
  opacity: 1;
}

.recent-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5%;
}

.recent {
  display: flex;
  align-items: center;
}

.other-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5%;
}

.other {
  display: flex;
  align-items: center;

}

.subheader {
  padding-left: 10%;
}

@media (max-width: 767px) {
  .card {
    width: 300px;
    height: 200px;
    border-radius: 10px;
    margin-left: 10px;
    margin-top: 10px;
  }

  .subheader {
    padding-left: 15%;

  }
}