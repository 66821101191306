.container-societycards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 5%;
    min-height: 100vh;
    padding-top: 0;
}

.custom-card {
    position: relative;
    width: 320px;
    height: 450px;
    margin: 3%;
    border-radius: 20px;
    border-bottom-left-radius: 160px;
    border-bottom-right-radius: 160px;
    box-shadow: 0 15px 0 #fff,
        inset 0 -15px 0 rgba(255, 255, 255, 0.24),
        0 45px 0 rgba(0, 0, 0, 0.15);
    overflow: hidden;
}

.custom-card::before {
    content: "";
    position: absolute;
    top: -140px;
    left: -40%;
    padding: 0;
    width: 100%;
    height: 120%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2));
    transform: rotate(35deg);
    pointer-events: none;
    filter: blur(5px);
}

.icon {
    position: relative;
    width: 140px;
    height: 120px;
    background: white;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    box-shadow: 0 15px 0 rgba(0, 0, 0, 0.1),
        inset 0 -8px 0 #fff;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.icon::before {
    content: "";
    position: absolute;
    top: 0;
    left: -50px;
    width: 50px;
    height: 50px;
    background: transparent;
    border-top-right-radius: 50px;
    box-shadow: 15px -15px 0 15px white;
}

.icon::after {
    content: "";
    position: absolute;
    top: 0;
    right: -50px;
    width: 50px;
    height: 50px;
    background: transparent;
    border-top-left-radius: 50px;
    box-shadow: -15px -15px 0 7px white;
}

.icon ion-icon {
    color: #fff;
    position: relative;
    font-size: 6em;
    z-index: 4;
    --ionicon-stroke-width: 24px;
}

.content {
    position: absolute;
    padding: 30px;
    padding-top: 0px;
    text-align: center;
}

.content h2 {
    font-size: 1.8em;
    color: #fff;
    margin-bottom: 15px;
}

.content p {
    color: #fff;
    line-height: 1.5em;
    /* Ensure this matches the height of your lines */
    max-height: 4.5em;
    /* line-height * number of lines (1.5em * 3 lines) */
    overflow: hidden;
    /* Hide content that overflows the max-height */
    text-overflow: ellipsis;
    /* Add ellipsis at the end */
    display: -webkit-box;
    /* Required for line clamping */
    -webkit-box-orient: vertical;
    /* Set the box orientation to vertical */
    -webkit-line-clamp: 3;
    /* Limit the text to 3 lines */
}


.effect {
    text-align: center;
    display: inline-block;
    position: relative;
    text-decoration: none;
    color: white;
    /* Replace with actual link text color */
    text-transform: capitalize;
    /* background-color: #f0f0f0; Replace with your desired background color */
    font-family: 'Roboto', sans-serif;
    /* Replace with your font family */
    font-size: 15px;
    padding: 20px 0px;
    width: 150px;
    border-radius: 4px;
    /* Replace with your actual border radius */
    overflow: hidden;
}

/* effect-1 styles */
.effect.effect-1 {
    transition: all 0.2s linear 0s;
}

.effect.effect-1::before {
    content: "►";
    font-size: 20px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    top: 0;
    opacity: 0;
    height: 100%;
    width: 50px;
    transition: all 0.2s linear 0s;
}

.effect.effect-1:hover {
    text-indent: -8px;
    color: lightgrey;
}

.effect.effect-1:hover::before {
    opacity: 1;
    color: lightgrey;
    text-indent: 0px;
}

@media (max-width: 480px) {
    .container-societycards {
        margin-bottom: 5%;
    }

    .custom-card {
        margin-top: 10%;

        box-shadow: 0 10px 0 #fff,
            inset 0 -10px 0 rgba(255, 255, 255, 0.24),
            0 30px 0 rgba(0, 0, 0, 0.15)
    }


}