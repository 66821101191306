.collapsed-sidebar {
    width: 100;
  }
  .Sidebar-menu{
    height: '100%'; 
    border-right: 0; 
  }
  .ant-menu-inline-collapsed{
    width: 50px;
  }
  .css-mz1onh-MuiGrid-root {
    background-color: white;
  }