.parent {
    z-index: 1;
    color: #00abe5;
    -webkit-text-stroke: 1px #000;
    /* WebKit-based browsers */
    text-stroke: 1px #000;
    padding-top: 1%;
    padding-bottom: 1%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.text-stroke-navbar {
    font-weight: bolder;
    color: #00abe5;
    -webkit-text-stroke: 0.5px #000;
    /* WebKit-based browsers */
    text-stroke: 0.5px #000;
}

.overlay {
    width: 100%;
    z-index: 100;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg,
            rgba(255, 255, 255, 1) 75%,
            rgba(255, 255, 255, 0.9) 80%,
            rgba(255, 255, 255, 0.25) 95%,
            rgba(255, 255, 255, 0) 100%);
}

.heading-text {
    padding-left: 5%;
    font-family: 'roboto';
    display: flex;
    font-weight: bold;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.heading-text .wrapper {
    padding-left: 1px;
    padding-right: 1px;
    padding-top: 2px;
    padding-bottom: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.heading-text .wrapper .letter {
    height: 100%;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    letter-spacing: 1px;
    transition: ease-out 1s;
    /* transform: translateY(20%); */
}

.heading-text .wrapper .shadow {
    transform: scale(1, -1);
    color: rgb(231, 231, 231);
    transition: ease-in 3s, ease-out 3s;

    -webkit-text-stroke: 0px #000;
}

.heading-text .wrapper:hover .letter {
    transform: translateY(-200%);
}

.heading-text .wrapper:hover .shadow {
    opacity: 0;
    transform: translateY(200%);
}

@media(max-width:600px) {
    .heading-text {
        font-size: 20px;
        font-family: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

    }

    .heading-text .wrapper {
        padding-left: 1px;
        padding-right: 1px;
        padding-top: 2px;
        padding-bottom: 2px;
        font-weight: bold;
    }

    .heading-text {
        padding-left: 3%;
    }

    .heading-text .wrapper .letter {
        transition: ease-out 1s;
        /* transform: translateY(10%); */
    }
}

.overlayhome {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.5), rgba(202, 202, 202, 0.5), rgba(143, 143, 143, 0.5));
    background-size: 400% 400%;
    animation: gradient 5s ease forwards;
    height: 100vh;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 70% 80%;
    }

    100% {
        background-position: 90% 100%;
    }
}